
































































































































































































































































































































































































































































































































































































































































































  import {Component, Vue} from "vue-property-decorator";

  @Component({
  name: 'ProfileResponsive',
})
  export default class ProfileResponsive extends Vue {
  protected sidebarOpen = false;
  protected notificationOpen = false;
  protected dropdownOpen = false;
}
